<template>
  <a-card>
    <a-form-model
        ref="search_form"
        :model="search_form"
        layout="inline"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="品 牌">
            <a-select
                v-model="search_form.principalId"
                show-search
                option-filter-prop="children"
                allow-clear
                placeholder="请输入关键字搜索"
                :filter-option="filterOption"
                style="width: 260px"
            >
              <a-select-option :value="item.id" v-for="item in principal_list" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
              label="下单日期"
          >
            <a-range-picker
                :placeholder="['开始日期', '结束日期']"
                format="YYYY-MM-DD"
                inputReadOnly
                @change="onChangeDate(arguments, 'order')"
                style="width: 260px"
                v-model="time"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
              label="脚本提交截止日期"
          >
            <a-range-picker
                :placeholder="['开始日期', '结束日期']"
                format="YYYY-MM-DD"
                inputReadOnly
                @change="onChangeDate(arguments, 'scriptSubmit')"
                style="width: 220px"
                v-model="time1"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row style="padding-top: 12px">
        <a-col :span="8">
          <a-form-model-item label="状 态">
            <a-select
                v-model="search_form.status"
                allow-clear
                placeholder="请选择"
                style="width: 260px"
            >
              <a-select-option :value="item.id" v-for="item in status_list" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
              label="完成日期"
          >
            <a-range-picker
                :placeholder="['开始日期', '结束日期']"
                format="YYYY-MM-DD"
                inputReadOnly
                @change="onChangeDate(arguments, 'finish')"
                style="width: 260px"
                v-model="time2"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
              label="成片提交截止日期"
          >
            <a-range-picker
                :placeholder="['开始日期', '结束日期']"
                format="YYYY-MM-DD"
                inputReadOnly
                @change="onChangeDate(arguments, 'product')"
                style="width: 220px"
                v-model="time3"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row style="padding-top: 12px">
        <a-col :span="8">
          <a-form-model-item label="类 型">
            <a-select
                v-model="search_form.orderTypeId"
                allow-clear
                placeholder="请选择"
                style="width: 260px"
            >
              <a-select-option :value="item.id" v-for="item in orderTypeId_list" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
              label="下单人"
          >
            <a-input
                placeholder="请输入"
                v-model="search_form.creatorName"
                style="width: 260px;"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item
              label="模板上线截止日期"
          >
            <a-range-picker
                :placeholder="['开始日期', '结束日期']"
                format="YYYY-MM-DD"
                inputReadOnly
                @change="onChangeDate(arguments, 'guideshootPublish')"
                style="width: 220px"
                v-model="time4"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row class="top_20">
        <a-col :span="16">
          <a-button
              type="primary"
              @click="actionButtons(1)"
              class="mr-10"
          >下单
          </a-button>
          <a-button
              type="danger"
              @click="actionButtons(4)"
              class="mr-10"
          >待接单工单
          </a-button>
          <a-button
              type="danger"
              @click="actionButtons(5)"
              class="mr-10"
          >待定稿工单
          </a-button>
          <a-button
              type="danger"
              @click="actionButtons(6)"
              class="mr-10"
          >待审核工单
          </a-button>
          <a-button
              type="danger"
              @click="actionButtons(7)"
              class="mr-10"
          >待验收工单
          </a-button>
        </a-col>
        <a-col class="tools">
          <a-button
              type="primary"
              @click="actionButtons(2)"
              class="mr-10"
          >查询
          </a-button>
          <a-button
              @click="actionButtons(3)"
          >重置
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>

    <div class="mt-40p">
      <a-table
          :columns="columns"
          :data-source="data_list"
          :pagination="false"
          :rowKey="(record, index) => index"
          :loading="loading"
          bordered
          :scroll="{ y: 450,x:1000 }"
          class="a-tables-scroll"
      >
        <div slot="status" slot-scope="text, record">
          <div v-if="record.status == 1 || record.status == 3 || record.status == 5 ||record.status == 7">
            <a-badge color="blue"/>
            <span style="color:#2A90F4;">
            {{
                record.status == 1 ? "草稿中" : record.status == 3 ? "策划中" : record.status == 5 ? "成片中" : record.status == 7 ? "上线中" : ""
              }}
          </span>
          </div>
          <div v-if="record.status == 2 || record.status == 4 || record.status == 6 ||record.status == 8">
            <a-badge color="#ff4446"/>
            <span style="color: #ff4446">
            {{
                record.status == 2 ? "待接单" : record.status == 4 ? "待定稿" : record.status == 6 ? "待审核" : record.status == 8 ? "待验收" : ""
              }}
          </span>
          </div>
          <div v-if="record.status == 9">
            <a-badge color="#0bc9b9"/>
            <span style="color: #0bc9b9">已完成</span>
          </div>
        </div>
        <div slot="operation" slot-scope="text, record">
          <a @click="actionButtons(8,record)" v-if="record.status == 1">编辑</a>
          <a @click="actionButtons(9,record)" v-else>详情</a>
        </div>
      </a-table>
      <!-- 分页配置 -->
      <base-pagination
          :currentPage="current"
          :pageSize="page_size"
          :total="total"
          :options="['10', '30', '50']"
          @onChange="onChange"
          @onShowSizeChange="onChange"
      >
      </base-pagination>
    </div>
  </a-card>
</template>

<script>

import _api from "@/api/contentProduction.js";

export default {
  name: "WorkOrderManagementList",
  data() {
    return {
      search_form: {},
      principal_list: [],
      status_list: [
        {
          id: 1,
          name: "草稿中"
        },
        {
          id: 2,
          name: "待接单"
        },
        {
          id: 3,
          name: "策划中"
        },
        {
          id: 4,
          name: "待定稿"
        },
        {
          id: 5,
          name: "成片中"
        },
        {
          id: 6,
          name: "待审核"
        },
        {
          id: 7,
          name: "上线中"
        },
        {
          id: 8,
          name: "待验收"
        },
        {
          id: 9,
          name: "已完成"
        },
      ],
      orderTypeId_list: [],
      columns: [
        {
          dataIndex: "orderCode",
          width: 80,
          title: "编号"
        },
        {
          scopedSlots: {customRender: "status"},
          width: 130,
          title: "状态"
        },
        {
          dataIndex: "principalName",
          width: 140,
          title: "品牌"
        },
        {
          dataIndex: "guideshootTotal",
          width: 140,
          title: "下单模板数量"
        },
        {
          dataIndex: "publishGuideshootTotal",
          width: 140,
          title: "上线模板数量"
        },
        {
          dataIndex: "orderDate",
          width: 120,
          title: "下单日期"
        },
        {
          dataIndex: "scriptSubmitDeadline",
          width: 150,
          title: "脚本提交截止日期"
        },
        {
          dataIndex: "productSubmitDeadline",
          width: 150,
          title: "成片提交截止日期"
        },
        {
          dataIndex: "guideshootPublishDate",
          width: 150,
          title: "模板上线截止日期"
        },
        {
          dataIndex: "finishDate",
          width: 120,
          title: "完成日期"
        },
        {
          dataIndex: "creatorName",
          width: 100,
          title: "下单人"
        },
        {
          dataIndex: "orderTypeName",
          width: 90,
          title: "类型"
        },
        {
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
          width: 100,
          fixed: "right",
          title: "操作"
        }
      ],
      data_list: [],
      loading: false,
      total: 0,
      current: 1,
      page_size: 10,
      time: [],
      time1: [],
      time2: [],
      time3: [],
      time4: [],
      time5: [],
    };
  },
  mounted() {
    this.getPrincipalsList();
    this.getWorkOrderManagementList();
    this.getOrderTypeIdList();
  },
  methods: {
    getWorkOrderManagementList() {
      this.loading = true;
      let params = this.search_form;
      params.page = this.current;
      params.size = this.page_size;
      _api.getWorkOrderManagementList(params).then(res => {
        if(res.code == 200) {
          this.data_list = res.data.list || [];
          this.total = res.data.total || 0;
        } else {
          this.data_list = [];
          this.total = 0;
          this.$message.error("获取工单列表失败");
        }
        this.loading = false;
      });
    }, // 列表
    getPrincipalsList() {
      _api.getPrincipalsList().then(res => {
        if(res.code == 200) {
          this.principal_list = res.data || [];
        } else {
          this.principal_list = [];
        }
      });
    }, // 品牌列表
    getOrderTypeIdList() {
      _api.getOrderTypeIdList().then(res => {
        if(res.code == 200) {
          this.orderTypeId_list = res.data || [];
        } else {
          this.orderTypeId_list = [];
        }
      });
    }, // 类型列表
    onChangeDate(dates, type) {
      let start_time = "";
      let end_time = "";
      const timeArray = dates[1];
      if(timeArray) {
        start_time = this.$moment(timeArray[0]).format("YYYY-MM-DD");
        end_time = this.$moment(timeArray[1]).format("YYYY-MM-DD");
      }
      switch(type) {
        case "order":
          this.search_form.orderDateStart = start_time;
          this.search_form.orderDateEnd = end_time;
          break;
        case "scriptSubmit":
          this.search_form.scriptSubmitDeadlineStart = start_time;
          this.search_form.scriptSubmitDeadlineEnd = end_time;
          break;
        case "finish":
          this.search_form.finishDateStart = start_time;
          this.search_form.finishDateEnd = end_time;
          break;
        case "product":
          this.search_form.productSubmitDeadlineStart = start_time;
          this.search_form.productSubmitDeadlineEnd = end_time;
          break;
        case "guideshootPublish":
          this.search_form.guideshootPublishDateStart = start_time;
          this.search_form.guideshootPublishDateEnd = end_time;
          break;
      }
      console.log(this.search_form);
    }, // 日期选择框
    //搜索
    actionButtons(type, record) {
      if(type != 1 && type != 2) {
        this.search_form = {};
        this.time = [];
        this.time1 = [];
        this.time2 = [];
        this.time3 = [];
        this.time4 = [];
        this.time5 = [];
      }
      switch(type) {
        case 1:
          console.log("新增");
          this.$router.push({
            name: "WorkOrderManagementPage",
            query: {
              type: "add",
            }
          });
          break;
        case 2:
          console.log("搜索");
          this.current = 1;
          this.page_size = 10;
          this.getWorkOrderManagementList();
          break;
        case 3:
          console.log("重置");
          this.current = 1;
          this.page_size = 10;
          this.getWorkOrderManagementList();
          break;
        case 4:
          console.log("待接单工单");
          this.$set(this.search_form, "status", 2);
          this.getWorkOrderManagementList();
          break;
        case 5:
          console.log("待定稿工单");
          this.$set(this.search_form, "status", 4);
          this.getWorkOrderManagementList();
          break;
        case 6:
          console.log("待审核工单");
          this.$set(this.search_form, "status", 6);
          this.getWorkOrderManagementList();
          break;
        case 7:
          console.log("待验收工单");
          this.$set(this.search_form, "status", 8);
          this.getWorkOrderManagementList();
          break;
        case 8:
          this.$router.push({
            name: "WorkOrderManagementPage",
            query: {
              id: record.id,
              type: "edit",
            }
          });
          break;
        case 9:
          this.$router.push({
            name: "WorkOrderManagementDetail",
            query: {
              id: record.id,
              orderCode: record.orderCode,
            }
          });
          break;
      }
    }, // 操作按钮
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }, // 品牌过滤
    onChange(current, page_size) {
      this.current = current;
      this.page_size = page_size;
      this.getWorkOrderManagementList();
    }, //翻页
  }
};
</script>

<style scoped lang="less">
.mt-40p {
  margin-top: 40px;
}

.top_20 {
  padding-top: 20px;
}

.tools {
  display: flex;
  justify-content: flex-end;
}

.mr-10 {
  margin-right: 20px;
}

.a-tables-scroll /deep/ .ant-table-body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }

  ul::-webkit-scrollbar { // 整个滚动条.
    width: 6px;
  }

  &::-webkit-scrollbar-thumb { // 滚动条上的滚动滑块
    border-radius: 8px;
    background-color: #7f7f7f;
  }

  &::-webkit-scrollbar-corner { // 当同时有垂直滚动条和水平滚动条时交汇的部分
    background_color: #242832;
  }

  &::-webkit-scrollbar-track { // 滚动条轨道
    border-radius: 0;
    background_color: #242832;
  }

}
</style>
